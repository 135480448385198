<template>
  <div class="row" >
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <Sidepanel @onDriverSelected="onDriverSelected" />
          <div class="inbox-rightbar">
            <!--              <Toolbar />-->
            <template v-if="driver">
              <div class="mt-0">
                <h5 class="font-18">
                  <span class="mr-3">{{driver['name']}} {{driver['other_names']}}</span>
                  <template v-if="driver['prospective_driver'] && driver['prospective_driver']['service_type']">
                    <span class="badge badge-dark p-1"> {{driver['prospective_driver']['service_type'] | serviceType}}</span>
                  </template>
                  <template v-else>
                    (No service selected)
                  </template>

                  <template v-if="driver['driver'] && driver['driver']['dossier']">
                    <span class="badge badge-success p-1 ml-2"> dossier: {{driver['driver']['dossier'] | serviceType}}</span>
                  </template>

                </h5>
              </div>
              <hr>
              <b-card :class="{'border-danger' : date_range.length>0}">

                <h4 class="card-title">Filter by date range</h4>

                <div class="row">

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Select date</label>
                      <date-picker v-model="date_range" @clear='removeDate' @change="dateRangeChanged" range append-to-body lang="en" confirm></date-picker>
                    </div>
                  </div>

                </div>
              </b-card>


              <b-card :class="{'border-danger' : !usePeriods && date_range.length===0 }">

                <h4 class="card-title">Filter by weeks</h4>

                <div class="row">

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>From</label>
                      <input type="week" class="form-control" v-model="startWeek" @change="weekChanged">
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>To</label>
                      <input type="week" class="form-control" v-model="endWeek" @change="weekChanged">
                    </div>
                  </div>

                </div>
              </b-card>
              <b-card :class="{'border-danger' : usePeriods}">
                <h4 class="card-title">Filter by periods, yearly, quarterly</h4>
                <div class="row">

                  <div class="col-md-12">
                    <div>
                      <b-form inline>
                        <select class="form-control mr-2" v-model="selectedYear">
                          <option :value="ys" v-for="ys in years">{{ys}}</option>
                        </select>

                        <select class="form-control mr-2" v-model="filterSelected" @change="onFilterPeriodsChanged">
                          <option v-for="option in filterOptions" :value="option.value"> {{option.text}} </option>
                        </select>

                        <b-button variant="primary" :disabled="periods.length <= 0" @click="usePeriods = true">Use periodic filter</b-button>
                      </b-form>
                    </div>
                  </div>


<!--                  <div class="col-md-9">-->
<!--                    <div class="form-group">-->
<!--                      <label><span class="mr-2">Enter periods</span>-->
<!--&lt;!&ndash;                        <a href="#" class="mr-2 text-info" @click.prevent="addAllPeriods" >add all periods</a>&ndash;&gt;-->
<!--&lt;!&ndash;                        <a href="#"  @click.prevent="removeAllPeriods" class="mr-2 text-danger">remove all periods</a>&ndash;&gt;-->
<!--                      </label>-->


<!--                    </div>-->
<!--                  </div>-->
                </div>

                <div class="row mt-4">
                  <div class="col-md-9">
                    <label class="">Selected periods</label>
                    <b-form-tags
                        input-id="tags-pills"
                        v-model="periods"
                        tag-variant="primary"
                        class="mr-2"
                        tag-pills
                        :tag-validator="periodTagValidator"
                        size="lg"
                        separator=" "
                        placeholder="Periods"
                    ></b-form-tags>
                  </div>
                </div>

              </b-card>



              <b-card>
                <h4 class="card-title">Actions</h4>
                <button class="btn btn-dark mr-4" @click="generateFinancialSheet"> <i class="fa fa-file-download"></i> Generate Financial Sheet For {{driver['name'] + ' ' +driver['other_names']}}</button>
                <button class="btn btn-info" @click="downloadFinancialSheetForDriver"> <i class="fa fa-file-download"></i> Download Financial Sheet For {{driver['name'] +' '+driver['other_names']}}</button>
              </b-card>


<!--           Remaining content here -------------   -->

              <template v-if="bSort && rSort">

                <b-card>
                  <h4 class="card-title"> Summary </h4>
                  <table class="table mt-4">
                    <tr>
                      <td><span class="text-danger">{{bSort['name']}} Soort</span></td>
                      <td>{{bSort['cost_description']}}</td>
                      <td class="text-right"><span class="font-15 font-weight-bold">{{bSort['name']}} Total = {{bSort['subTotal'] | toCurrencyFormat}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="text-danger">{{rSort['name']}} Soort</span></td>
                      <td>{{rSort['cost_description']}}</td>
                      <td class="text-right"> <span class="font-15 font-weight-bold">{{rSort['name']}} Total = {{rSort['costSubTotal'] | toCurrencyFormat}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="text-danger">{{rSort['name']}} Soort</span></td>
                      <td>{{rSort['income_description']}}</td>
                      <td class="text-right"> <span class="font-15 font-weight-bold">{{rSort['name']}} Total = {{rSort['incomeSubTotal'] | toCurrencyFormat}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="text-danger">Grand Total</span></td>
                      <td></td>
                      <td class="text-right"> <span class="font-15 font-weight-bold"> Total = {{grandTotal | toCurrencyFormat}}</span></td>
                    </tr>
                  </table>

                </b-card>

              </template>

              <template v-if="bSort">
                <b-card class="mt-2">
                    <h4 class="card-title">B Soort breakdown</h4>

                    <table class="table mt-2 table-striped">
                    <thead>
                    <tr>
                      <th class="text-left">CDGRBREKENING</th>
                      <th>OMSCHR</th>
                      <th class="text-right">Bedrag</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="sort in bSort['incomeData']">
                      <th>{{sort['CDGRBREKENING']}}</th>
                      <th>{{sort['OMSCHR']}}</th>
                      <th class="text-right">{{sort['Bedrag'] | toCurrencyFormat}}</th>
                    </tr>
                    <tr v-for="sort in bSort['costData']">
                      <th>{{sort['CDGRBREKENING']}}</th>
                      <th>{{sort['OMSCHR']}}</th>
                      <th class="text-right">{{sort['Bedrag'] | toCurrencyFormat}}</th>
                    </tr>
                    <tr>
                      <td colspan="3" class="text-right">
                        subTotal: {{bSort['subTotal'] | toCurrencyFormat}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <b-card>
                    <button class="btn btn-outline-dark">Generate Bedrijfsvermogen Red Cabs</button>
                  </b-card>
                  <table class="table mt-4">
                    <tr>
                      <td><span class="text-danger">0650</span></td>
                      <td>Bedrijfsvermogen Red Cabs</td>
                      <td class="text-right"> <span class="font-15 font-weight-bold">{{'0.00' | toCurrencyFormat}}</span></td>
                    </tr>
                  </table>

                  <table class="table mt-4">
                      <tr>
                        <td><span class="text-danger">{{bSort['name']}} Soort</span></td>
                        <td>{{bSort['description']}}</td>
                        <td class="text-right"><span class="font-15 font-weight-bold">{{bSort['name']}} Total = {{bSort['subTotal'] | toCurrencyFormat}}</span></td>
                      </tr>
                    </table>
                </b-card>
              </template>

              <template v-if="rSort">
                <b-card class="mt-2">
                  <h4 class="card-title">R Soort breakdown</h4>
                  <span class="text-danger">{{rSort['cost_description']}}</span>
                  <table class="table mt-2 table-striped">
                    <thead>
                    <tr>
                      <th class="text-left">CDGRBREKENING</th>
                      <th>OMSCHR</th>
                      <th class="text-right">Bedrag</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="sort in rSort['costData']">
                      <th>{{sort['CDGRBREKENING']}}</th>
                      <th>{{sort['OMSCHR']}}</th>
                      <th class="text-right">{{sort['Bedrag'] | toCurrencyFormat}}</th>
                    </tr>
                    </tbody>
                  </table>

                  <table class="table mt-4">
                    <tr>
                      <td><span class="text-danger">{{rSort['name']}} Soort</span></td>
                      <td>{{rSort['cost_description']}} Total</td>
                      <td class="text-right"> <span class="font-15 font-weight-bold">{{rSort['name']}} Total = {{rSort['costSubTotal'] | toCurrencyFormat}}</span></td>
                    </tr>
                  </table>

                </b-card>
                <b-card class="mt-2">
                  <h4 class="card-title">R Soort breakdown</h4>
                  <span class="text-danger">{{rSort['income_description']}}</span>
                  <table class="table mt-2 table-striped">
                    <thead>
                    <tr>
                      <th class="text-left">CDGRBREKENING</th>
                      <th>OMSCHR</th>
                      <th class="text-right">Bedrag</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="sort in rSort['incomeData']">
                      <th>{{sort['CDGRBREKENING']}}</th>
                      <th>{{sort['OMSCHR']}}</th>
                      <th class="text-right">{{sort['Bedrag'] | toCurrencyFormat}}</th>
                    </tr>
                    </tbody>
                  </table>
                  <table class="table mt-4">
                    <tr>
                      <td><span class="text-danger">{{rSort['name']}} Soort</span></td>
                      <td>{{rSort['income_description']}} Total</td>
                      <td class="text-right"> <span class="font-15 font-weight-bold">{{rSort['name']}} Total = {{rSort['incomeSubTotal'] | toCurrencyFormat}}</span></td>
                    </tr>
                  </table>
                </b-card>
              </template>


            </template>

          </div >

        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->
    </div>
  </div>
</template>

<script>

import Sidepanel from "./f-data-sidepanel";
import DatePicker from "vue2-datepicker";

import moment from "moment";

export default {
  name: "generator",
  components: {
    Sidepanel,
    DatePicker,

  },
  data(){
    return {
      startWeek: '',
      periods: [],
      endWeek: '',
      date_range:[],
      bSort: null,
      rSort: null,
      grandTotal: null,
      driver: null,
      filterOptions: [
        { value: null, text: '-- Please select a filter --' },
        { value: 'first-quarter', text: 'First quarter' },
        { value: 'second-quarter', text: 'Second quarter' },
        { value: 'third-quarter', text: 'Third quarter' },
        { value: 'forth-quarter', text: 'Forth quarter' },
        { value: 'full-year', text: 'Full year' },
        { value: 'clear', text: 'Clear all' }
      ],
      filterSelected: null,
      years: [],
      selectedYear: null,
      usePeriods: false
    }
  },
    methods: {
      removeDate(){
        this.date_range =[];
      },

      dateRangeChanged(){
        this.usePeriods = false;
        this.generateFinancialSheet();
      },

    onFilterPeriodsChanged(){
      console.log('selected', this.filterSelected)
      if(this.filterSelected === 'first-quarter'){
        this.periods.push(1,2,3)
        this.sortPeriods();
      }
      if(this.filterSelected === 'second-quarter'){
        this.periods.push(4,5,6)
        this.sortPeriods();
      }
      if(this.filterSelected === 'third-quarter'){
        this.periods.push(7,8,9)
        this.sortPeriods();
      }
      if(this.filterSelected === 'forth-quarter'){
        this.periods.push(10,11,12)
        this.sortPeriods();
      }
      if(this.filterSelected === 'full-year'){
        this.addAllPeriods();
        this.sortPeriods();
      }
      if(this.filterSelected === 'clear'){
        this.removeAllPeriods();
      }

      this.filterSelected = null
    },

    sortPeriods(){
      this.periods.sort(function(a, b) {
        return a - b;
      });
    },

    periodTagValidator(tag) {
      // Individual tag validator function
      try {
        const num = JSON.parse(tag);
        if (isNaN(tag)){
          return false
        }else if(!Number.isInteger(num)){
          return false
        }else if(num < 1 || num > 12){
          return false
        }else {
          return true;
        }
      }catch (e){
      }

    },
    addAllPeriods(){
      this.periods = [1,2,3,4,5,6,7,8,9,10,11,12]
    },

    removeAllPeriods(){
      this.periods = [];
    },

      getDownloadUrl(token){
        return process.env.VUE_APP_API_BASE_URL + '/download-financial-sheet/' + token
      },

      weekChanged(){
        // this.generateFinancialSheet();
        this.usePeriods = false
      },

      downloadFinancialSheetForDriver(driver){

        const payload = {
          "startWeek": this.startWeek,
          "endWeek": this.endWeek,
          'driverIds': [
            this.driver['id']
          ],
          'periods': this.periods,
          'year': this.selectedYear,
          'usePeriods': this.usePeriods
        }

        this.$store.dispatch('downloadFinancialSheet', payload).then((token) => {
          console.log('returned', token)
          const url = this.getDownloadUrl(token);
          window.open(url, '_blank');
        })
      },

      onDriverSelected(driver){
        console.log('driver selected', driver)
        if(!driver){
          return;
        }
        this.driver = driver;
      },

      generateFinancialSheet(){


        const payload = {
          'start-week': this.startWeek,
          'end-week': this.endWeek,
          'driver-id': this.driver['id'],
          'periods': this.periods,
          'usePeriods': this.usePeriods,
          'year': this.selectedYear,
          'date_range':this.date_range
        }
        this.$store.dispatch('generateFinancialSheet', payload).then((data) => {
          console.log('returned', data)
          this.startWeek = data['startWeek']
          this.endWeek = data['endWeek']
          this.bSort = data['output']['bSort']
          this.rSort = data['output']['rSort']
          this.grandTotal = data['output']['grandTotal']
        })
      }
  },
  watch: {
    driver: function (driver){
      // if(driver){
      //
      // }
      // this.generateFinancialSheet(this.usePeriods)
    },
    usePeriods: function (value){
      if(!value){
        this.removeAllPeriods();
      }
    },
    '$store.getters.GET_AVAILABLE_YEARS': function (ys){
      this.years = ys;
      if(this.years.length > 1){
        this.selectedYear = this.years[0]
      }

    }
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Drivers financial sheet')
    this.$store.dispatch('getAvailableYears');
  },
}
</script>